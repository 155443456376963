<!-- splash screen -->
<div #splashScreen id="splash-screen" *ngIf="splashScreenService.iSLoginSplash | async" >
  <div style="height:75px;margin:0 auto;text-align:center">
      <img src="assets/img/logo.png" alt="Logo" style="width: auto;height: 100%;margin: auto;margin-bottom: 30px"/>
  </div>
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
