<!-- Page Content -->
<div class="content success-page-cont" style="padding:8vh 0">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <!-- Success Card -->
                <div class="card success-card" >
                    <div class="card-body">
                        <div class="success-cont">
                            <i class="fas fa-exclamation-triangle"></i>
                            <h3>{{"Sorry"|translate}}</h3>
                            <h5>{{"NotFoundData"|translate}}</h5>
                        </div>
                    </div>
                </div>
                <!-- /Success Card -->
            </div>
        </div>
    </div>
</div>
<!-- /Page Content -->