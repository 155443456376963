<ng-container *ngIf="user">
  <div class="card card-custom" [formGroup]="formGroup">
    <!-- begin::Loading -->
    <ng-container *ngIf="isLoading$ | async">
      <div class="progress progress-modal">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </ng-container>
    <!-- end::Loading -->
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{ "NotificationsSetting" | translate }}</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Change your Notifications Setting</span>
      </div>
      <div class="card-toolbar">
          <button type="submit" [disabled]="formGroup.invalid" class="btn btn-success mr-2" (click)="save()">{{"SaveChanges"|translate}}</button>
        <button type="reset" class="btn btn-secondary" (click)="cancel()">{{ "Cancel" | translate }}</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div class="form">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">{{"Email" | translate}}</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input type="checkbox" formControlName="isAllowNotificationByEmail" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">{{"SMS" | translate}}</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input type="checkbox" formControlName="isAllowNotificationBySMS" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">{{"Phone" | translate}}</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input type="checkbox" formControlName="isAllowNotificationByMobile" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">{{"Web" | translate}}</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-sm">
              <label>
                <input type="checkbox" formControlName="isAllowNotificationByWeb" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--end::Form-->
  </div>
</ng-container>
