 <!-- Page Title -->
<div class="page-title-area" [style.background-image]="image != '' ? 'url(' + environment.apiUrl + 'img/'+ folder +'/natural/' + image + ')' : 'page-title-bg.jpg'" >
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>{{title | translate}}</h2>
                    <ul>
                        <li>
                            <a [routerLink]="'/' + parentPage">{{parentPage | titlecase |translate}}</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            {{title | translate}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->