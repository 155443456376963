<div class="alert alert-custom alert-light-warning fade show mb-10" role="alert">
  <div class="alert-icon">
    <span class="svg-icon svg-icon-3x svg-icon-warning" [inlineSVG]="'./assets/media/svg/icons/Code/Info-circle.svg'" cacheSVG="true"> </span>
  </div>
  <div class="alert-text font-weight-bold">{{"ThereIsNo" | translate}} {{title | translate}}</div>
  <div class="alert-close">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">
        <i class="ki ki-close"></i>
      </span>
    </button>
  </div>
</div>
